<template lang="">
    <v-card>
        <s-crud
            :config="config"
            title="Programación de Pagos"
            :filter="filter"
            search-input
            :single-row="false"
            save
            ref="dataPaymentScheduling"
            @save="savePays()"
            @rowSelected="selectedPays($event)"
            
        >
            <template v-slot:filter>
                <v-container>
                    <v-row style="margin-bottom: 5px;">
                        <v-col cols="3">
                            <s-text
                                v-model="filter.PrdCardNameRc"
                                label="Proveedor"
                            ></s-text>
                        </v-col>
                        <v-col cols="3">
                            <s-date
                                v-model="filter.PsgDateDesde"
                                label="Fecha Desde"
                            ></s-date>
                        </v-col>
                        <v-col cols="3">
                            <s-date
                                v-model="filter.PsgDateHasta"
                                label="Fecha Hasta"
                            ></s-date>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </s-crud>
        <v-dialog
          v-model="dialog"
          max-width="900"
        >
        
        <v-card>
            <v-container>
                <v-data-table
                dense
                fixed-header=""
                :items="itemsPaysSelected"
                :headers="headersProgramados"
                :items-per-page="5"
                >
                <template v-slot:item.AsgAmount="{ item }">
                    <s-text
                        label=""
                        v-model="item.AsgAmount"
                        type="decimal"
                    ></s-text>
                </template>
                </v-data-table>
                <v-row>
                    <v-col align="right">
                        <v-btn
                        color="success"
                        @click="savePaysSelected()"
                        >
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
          </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
    import SText from '../../../components/Utils/SText.vue';
    import PaymentScheduling from '@/services/Technicalassistance/PaymentScheduling';

    export default {
        components: {
            SText
        },
        data() {
            return {
                dialog: false,
                itemsPays: [],
                itemsPaysSelected: [],
                filter: {
                    PsgDateDesde: "",
                    PsgDateHasta: "",
                    PrdCardNameRc: "",
                },
                itemSelected: {},
                config: {
                    service: PaymentScheduling,
                    model: {
                        RcfID: "ID",
                        // AsgAmount: "",
                    },
                    headers: [
                    // { text: 'ID', value: 'RcfID' },
                    { text: 'Cod', value: 'PrdCardCodeRc' },
                    { text: 'Proveedor', value: 'PrdCardNameRc' },
                    { text: 'Nro de Guia', value: 'RcfNumberReferralGuide' },
                    // { text: 'Monto', value: 'AsgAmount', width: '150px', align: 'center' },
                    { text: 'Semana', value: 'PsgWeek' },
                    { text: 'Nave', value: 'TypeShipName' }
                    ]
                },
                headersProgramados: [
                    // { text: "ID", value: "RcfID" },
                    { text: "Cod", value: "PrdCardCodeRc" },
                    { text: "Proveedor", value: "PrdCardNameRc" },
                    { text: "Guia", value: "RcfNumberReferralGuide" },
                    { text: "Monto", value: "AsgAmount", width: '150px', align: 'center' },
                    { text: "Semana", value: "PsgWeek" },
                    { text: "TypeShip", value: "TypeShipName" }
                ],
                AsgAmount: ""
            }
        },
        methods: {
            async savePays() {
                console.log(this.itemsPays);
                this.itemsPaysSelected = [];

                this.itemsPaysSelected = Object.values(this.itemsPays.reduce((acc, item) => {
                    if (!acc[item.PrdCardCodeRc]) {
                        acc[item.PrdCardCodeRc] = { ...item };
                    } else {
                        acc[item.PrdCardCodeRc].RcfNumberReferralGuide += ` | ${item.RcfNumberReferralGuide}`;
                    }
                    return acc;
                }, {}));
                this.dialog = true;
            },
            async savePaysSelected() {
                const idRegistrados = [];
                const originalItems = [...this.itemsPaysSelected];
                const itemRegistrados = [];

                for (const item of [...originalItems]) {
                    item.usrCreateID = this.$fun.getUserID();
                    item.AsgAmount = item.AsgAmount ? parseFloat(item.AsgAmount) : 0;
                    try {
                        const r = await PaymentScheduling.listRegistrados(item.RcfID, this.$fun.getUserID());
                        if (r.status === 200 && r.data.length > 0) {
                            const idx = originalItems.findIndex(el => el.RcfID === item.RcfID);
                            if (idx >= 0) {
                                originalItems[idx].SpsId = r.data[0].SpsId;
                                itemRegistrados.push(originalItems[idx]);
                                originalItems.splice(idx, 1);
                            }

                            idRegistrados.push(r.data[0].RcfID);
                        }
                    } catch (error) {
                        console.error(`Error processing item ${item.RcfID}:`, error);
                    }
                }

                this.$fun.alert('Estas seguro de registrar los pagos?', 'question')
                    .then(async (resp) => {
                        if (resp.value) {
                            for (const item of originalItems) {
                                try {
                                    await PaymentScheduling.saveScheduledPayments(item, this.$fun.getUserID()).then((resp) => {
                                        if (resp.status == 200) {
                                            // console.log("Pago registrado", resp.data);
                                        }
                                    });
                                } catch (error) {
                                    console.error(`Error processing item ${item.RcfID}:`, error);
                                }
                            }

                            // Insert Data Table

                            for (const item of this.itemsPays) {
                                try {
                                    await PaymentScheduling.saveScheduledPaymentsData(item, this.$fun.getUserID()).then((resp) => {
                                        if (resp.status == 200) {
                                            // console.log("Pago registrado", resp.data);
                                        }
                                    });
                                } catch (error) {
                                    console.error(`Error processing item ${item.RcfID}:`, error);
                                }
                            }

                            // Update
                            if (itemRegistrados.length > 0){
                                this.$fun.alert('Los pagos con id :' + idRegistrados.join(', ') + ' ya fueron registrados, quiere actualizarlos?', 'question')
                                    .then(async (resp) => {
                                        if (resp.value) {
                                            for (const item of itemRegistrados) {
                                                try {
                                                    const resp = await PaymentScheduling.saveScheduledPayments(item, this.$fun.getUserID());
                                                    if (resp.status == 200) {
                                                        // console.log("Pago registrado", resp.data);
                                                    }
                                                } catch (error) {
                                                    console.error(`Error processing item ${item.RcfID}:`, error);
                                                }
                                            }

                                            if(originalItems.length > 0 && itemRegistrados.length > 0){
                                                this.$fun.alert('Los pagos han sido registrados y actualizados', 'success');
                                                this.$refs.dataPaymentScheduling.refresh();
                                                this.itemsPaysSelected = [];
                                                this.dialog = false;
                                            }else if(itemRegistrados.length > 0){
                                                this.$fun.alert('Los pagos han sido actualizados', 'success');
                                                this.$refs.dataPaymentScheduling.refresh();
                                                this.itemsPaysSelected = [];
                                                this.dialog = false;
                                            }
                                        }else{
                                            if(originalItems.length > 0){
                                                this.$fun.alert('Los pagos han sido registrados', 'success');
                                                this.$refs.dataPaymentScheduling.refresh();
                                                this.itemsPaysSelected = [];
                                                this.dialog = false;
                                            }
                                        }
                                    });
                            }else{
                                if(originalItems.length > 0){
                                    this.$fun.alert('Los pagos han sido registrados', 'success');
                                    this.$refs.dataPaymentScheduling.refresh();
                                    this.itemsPaysSelected = [];
                                    this.dialog = false;
                                }
                            }
                        }
                    });
            },
            selectedPays(items) {
                this.itemsPays = items;
            }
        }
    }
</script>