import Service from "../Service";
 
const resource = "TasPaymentScheduling/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    list(requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID: requestID },
        });
    },

    listRegistrados(RcfID, requestID) {
        return Service.post(resource + "listRegistrados", {}, {
            params: { RcfID:RcfID, requestID: requestID },
        });
    },

    saveScheduledPayments(data, requestID) {
        return Service.post(resource + "saveScheduledPayments", data, {
            params: { requestID: requestID },
        });
    },

    saveScheduledPaymentsData(data, requestID) {
        return Service.post(resource + "saveScheduledPaymentsData", data, {
            params: { requestID: requestID },
        });
    },
};